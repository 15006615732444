import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

import Offcanvas from "./offcanvas"

// vendor.min.css del theme desglosado (se desactivan los que no se usan)
import "../assets/vendor/css/feather.min.css"
//import '../assets/vendor/css/iziToast.min.css'
//import '../assets/vendor/css/nouislider.min.css'
import "../assets/vendor/css/owl.carousel.min.css"
import "../assets/vendor/css/socicon.min.css"
// fontawesome
import "@fortawesome/fontawesome-free/css/regular.css"
//import '@fortawesome/fontawesome-free/css/brands.css'
//import '@fortawesome/fontawesome-free/css/solid.css'
import "@fortawesome/fontawesome-free/css/all.css"
// propios
import "../assets/old/css/theme.css"

import logoDark from "../assets/old/img/logo/logo-dark.svg"
import megaMenu from "../assets/old/img/shop/mega-menu.jpg"
import logoLight from "../assets/old/img/logo/logo-light.png"
import pdpPng from "../assets/old/img/footer/pdp.png"
import ssn from "../assets/old/img/footer/ssn.png"
import afip from "../assets/old/img/footer/afip.jpg"

export default class extends React.Component {
  componentDidMount = () => {
    const $ = window.$

    // Sticky navbar
    const navbar = $(".navbar-sticky")
    if (navbar.length) {
      const body = $("body")
      const navbarH = navbar.outerHeight()
      $(window).on("scroll", function() {
        if ($(this).scrollTop() > 600) {
          navbar.addClass("navbar-stuck")
          if(!navbar.hasClass('navbar-floating')) {
          	body.css('padding-top', navbarH)
          }
        } else {
          navbar.removeClass("navbar-stuck")
          body.css("padding-top", 0)
        }
      })
    }

    // Animated scroll to top button (falta agregar el unmount)
    const trigger = $(".scroll-to-top-btn")
    if (trigger.length > 0) {
      $(window).on("scroll", function() {
        if ($(this).scrollTop() > 600) {
          trigger.addClass("visible")
        } else {
          trigger.removeClass("visible")
        }
      })
      trigger.on("click", function(e) {
        e.preventDefault()
        $("html, body")
          .stop()
          .animate({ scrollTop: 0 }, 1200, "easeOutExpo")
      })
    }
  }

  componentWillUnmount = () => {
    window.$(window).off("scroll")
  }

  getActiveClassForMenu = (menuName, alwaysClasses, activeClass) => {
    return menuName === this.props.menuActivo ? alwaysClasses + " " + activeClass : alwaysClasses
  }

  render = () => (
    <>
      <Helmet titleTemplate="%s - Web del Seguro" defaultTitle="Web del Seguro" defer={false}>
        <meta name="author" content="dosbarras.com" />
        <meta name="keywords" content="cotizador, seguros, autos" />
        <meta name="theme-color" content="#DC3D25" />
        <meta name="msapplication-navbutton-color" content="#DC3D25" />
        <meta name="msapplication-TileColor" content="#DC3D25" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
        <meta name="facebook-domain-verification" content="nvnyvo9wkg9fxw3akaoo1skjb2o8pa" />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="mask-icon" color="#343b43" href="/safari-pinned-tab.svg" />
      </Helmet>

      <Offcanvas>
        {/* Off-Canvas Menu */}
        <div
          className="offcanvas-container is-triggered offcanvas-container-reverse"
          id="mobile-menu"
        >
          <span className="offcanvas-close">
            <i className="fe-icon-x" />
          </span>
          <div className="px-4 pb-4">
            <h6>Menu</h6>
          </div>
          <div
            className="offcanvas-scrollable-area border-top"
            style={{ height: "calc(100% - 235px)", top: "104px" }}
          >
            {/* Mobile Menu */}
            <div className="accordion mobile-menu" id="accordion-menu">
              {/* Inicio */}
              <div className="card">
                <div className="card-header">
                  <Link
                    className={this.getActiveClassForMenu("inicio", "mobile-menu-link", "active")}
                    to="/"
                  >
                    Inicio
                  </Link>
                </div>
              </div>
              {/* Sobre Nosotros */}
              <div className="card">
                <div className="card-header">
                  <Link
                    className={this.getActiveClassForMenu("nosotros", "mobile-menu-link", "active")}
                    to="/nosotros"
                  >
                    Sobre Nosotros
                  </Link>
                </div>
              </div>
              {/* Cotizar Seguros */}
              <div className="card">
                <div className="card-header">
                  <Link
                    className={this.getActiveClassForMenu(
                      "cotizar-seguros",
                      "mobile-menu-link",
                      "active"
                    )}
                    to="/seguros"
                  >
                    Cotizar Seguros
                  </Link>
                  {/* eslint-disable-next-line */}
                  <a
                    className="collapsed"
                    href="/"
                    data-target="#blog-submenu"
                    data-toggle="collapse"
                  />
                </div>
                <div className="collapse" id="blog-submenu" data-parent="#accordion-menu">
                  <div className="card-body">
                    <ul>
                      <li className="dropdown-header">Seguros</li>
                      <li className="dropdown-item">
                        <Link to="/">Seguro Automotor</Link>
                      </li>
                      <li className="dropdown-item">
                        <Link to="/seguros/hogar/" activeClassName="active">
                          Seguro Hogar
                        </Link>
                      </li>
                      <li className="dropdown-item">
                        <Link to="/seguros/comercio">Seguro Comercio</Link>
                      </li>
                      <li className="dropdown-item">
                        <Link to="/seguros/cotizar-art">Seguro ART</Link>
                      </li>
                      <li className="dropdown-item">
                        <Link to="/seguros/tecnologia">Seguro Tecnología</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* Siniestros */}
              <div className="card">
                <div className="card-header">
                  <Link
                    className={this.getActiveClassForMenu(
                      "siniestros",
                      "mobile-menu-link",
                      "active"
                    )}
                    to="/siniestros"
                  >
                    Siniestros
                  </Link>
                </div>
              </div>
              {/* Preguntas Frecuentes */}
              <div className="card">
                <div className="card-header">
                  <Link
                    className={this.getActiveClassForMenu("faq", "mobile-menu-link", "active")}
                    to="/preguntas-frecuentes"
                  >
                    Preguntas Frecuentes
                  </Link>
                </div>
              </div>
              {/* Blog */}
              <div className="card">
                <div className="card-header">
                  <a
                    className="mobile-menu-link"
                    href="https://blog.webdelseguro.com.ar"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Blog
                  </a>
                </div>
              </div>
              {/* Contacto */}
              <div className="card">
                <div className="card-header">
                  <Link
                    className={this.getActiveClassForMenu("contacto", "mobile-menu-link", "active")}
                    to="/contacto"
                  >
                    Contacto
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="offcanvas-footer px-4 pt-3 pb-2 text-center">
            <a
              className="social-btn sb-style-3 sb-facebook"
              href="https://www.facebook.com/webdelseguro/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="socicon-facebook" />
            </a>
            <a
              className="social-btn sb-style-3 sb-instagram"
              href="https://www.instagram.com/webdelseguro/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="socicon-instagram" />
            </a>
            <a
              className="social-btn sb-style-3 sb-youtube"
              href="https://www.youtube.com/channel/UCngnk9h5p6NHiDPAxsLelLg/featured"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="socicon-youtube" />
            </a>
          </div>
        </div>

        {/* Navbar: Default */}
        {/* Remove "navbar-sticky" class to make navigation bar scrollable with the page. */}
        <header className="navbar-wrapper navbar-sticky">
          <div className="d-table-cell align-middle pr-md-3">
            <Link className="navbar-brand mr-1" to="/">
              <img src={logoDark} alt="Web del Seguro" width="127" height="61" />
            </Link>
          </div>
          <div className="d-table-cell w-100 align-middle pl-md-3">
            <div className="navbar-top d-none d-lg-flex justify-content-between align-items-center">
              <div />
              <div>
                <div>
                  <a
                    className="navbar-link mr-3"
                    href="https://api.whatsapp.com/send?phone=5491123552334&text=Necesito%20una%20cotizaci%C3%B3n%20a%20medida"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-whatsapp" />
                    011 2355-2334
                  </a>
                  <a className="navbar-link mr-3" href="tel:08103457676">
                    <i className="fe-icon-phone" />
                    0810 345 7676
                  </a>
                  <a
                    className="navbar-link mr-3"
                    href="mailto:info@webdelseguro.com.ar"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fe-icon-mail" />
                    info@webdelseguro.com.ar
                  </a>
                  <a
                    className="social-btn sb-style-3 sb-facebook"
                    href="https://www.facebook.com/webdelseguro/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="socicon-facebook" />
                  </a>
                  <a
                    className="social-btn sb-style-3 sb-instagram"
                    href="https://www.instagram.com/webdelseguro/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="socicon-instagram" />
                  </a>
                  <a
                    className="social-btn sb-style-3 sb-youtube"
                    href="https://www.youtube.com/channel/UCngnk9h5p6NHiDPAxsLelLg/featured"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="socicon-youtube" />
                  </a>
                </div>
              </div>
            </div>
            <div className="navbar justify-content-end justify-content-lg-between">
              {/* Main Menu */}
              <ul className="navbar-nav d-none d-lg-block">
                {/* Inicio */}
                <li className={this.getActiveClassForMenu("inicio", "nav-item", "active")}>
                  <Link to="/" className="nav-link">
                    Inicio
                  </Link>
                </li>
                {/* Nosotros */}
                <li className={this.getActiveClassForMenu("nosotros", "nav-item", "active")}>
                  <Link to="/nosotros" className="nav-link">
                    Sobre Nosotros
                  </Link>
                </li>
                {/* Cotizar Seguros */}
                <li
                  className={this.getActiveClassForMenu(
                    "cotizar-seguros",
                    "nav-item mega-dropdown-toggle d-none d-xl-inline-block",
                    "active"
                  )}
                >
                  <Link className="nav-link" to="/seguros">
                    Cotizar Seguros
                  </Link>
                  <div className="dropdown-menu mega-dropdown">
                    <div className="d-flex">
                      <div className="column">
                        <div className="widget widget-custom-menu">
                          <h4 className="widget-title">Seguros</h4>
                          <ul>
                            <li>
                              <Link to="/" activeClassName="active">
                                Seguro Automotor
                              </Link>
                            </li>
                            <li>
                              <Link to="/seguros/hogar" activeClassName="active">
                                Seguro Hogar
                              </Link>
                            </li>
                            <li>
                              <Link to="/seguros/comercio">Seguro Comercio</Link>
                            </li>
                            <li>
                              <Link to="/seguros/cotizar-art">Seguro ART</Link>
                            </li>
                            <li>
                              <Link to="/seguros/tecnologia">Seguro Tecnología</Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="column p-0 mr-0 ml-3" style={{ width: "320px" }}>
                        <a className="d-block" href="/seguros">
                          <img src={megaMenu} alt="Cotizar Seguros" />
                        </a>
                      </div>
                    </div>
                  </div>
                </li>
                {/* Siniestros */}
                <li className={this.getActiveClassForMenu("siniestros", "nav-item", "active")}>
                  <Link to="/siniestros" className="nav-link">
                    Siniestros
                  </Link>
                </li>
                {/* Preguntas Frecuentes */}
                <li className={this.getActiveClassForMenu("faq", "nav-item", "active")}>
                  <Link to="/preguntas-frecuentes" className="nav-link">
                    Preguntas Frecuentes
                  </Link>
                </li>
                {/* Blog */}
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="https://blog.webdelseguro.com.ar"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Blog
                  </a>
                </li>
                {/* Contacto */}
                <li className={this.getActiveClassForMenu("contacto", "nav-item", "active")}>
                  <Link to="/contacto" className="nav-link">
                    Contacto
                  </Link>
                </li>
              </ul>
              <div>
                <ul className="navbar-buttons d-inline-block align-middle mr-lg-2">
                  <li className="d-block d-lg-none">
                    <a href="#mobile-menu" data-toggle="offcanvas">
                      <i className="fe-icon-menu" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="http://m.me/webdelseguro?ref=web"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-facebook-messenger" style={{ color: "#0084ff" }} />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://api.whatsapp.com/send?phone=5491123552334&text=Necesito%20una%20cotizaci%C3%B3n%20a%20medida"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-whatsapp" style={{ color: "#20B038" }} />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </header>
      </Offcanvas>

      {this.props.children}

      {/* Footer */}
      <footer className="bg-dark pt-5">
        <div className="container pt-2">
          <div className="row">
            <div className="col-lg-3 pb-4 mb-2 text-center text-lg-left">
              <Link className="navbar-brand d-inline-block mb-4" to="/">
                <img src={logoLight} alt="Web del Seguro" />
              </Link>
              <ul className="list-icon text-sm pb-2">
                <li>
                  <i className="fe-icon-map-pin text-white opacity-60" />
                  <Link className="navi-link text-white" to="/contacto#map">
                    &nbsp;Alvear 1563, Ramos Mejía
                  </Link>
                </li>
                <li>
                  <i className="fe-icon-phone text-white opacity-60" />
                  <a className="navi-link text-white" href="tel:08103457676">
                    &nbsp;0810 345 7676
                  </a>
                </li>
                <li>
                  <i className="fab fa-whatsapp text-white opacity-60" />
                  <a
                    className="navi-link text-white"
                    href="https://api.whatsapp.com/send?phone=5491123552334&text=Necesito%20una%20cotizaci%C3%B3n%20a%20medida"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    &nbsp;011 2355-2334
                  </a>
                </li>
                <li>
                  <i className="fe-icon-mail text-white opacity-60" />
                  <a
                    className="navi-link text-white"
                    href="mailto:info@webdelseguro.com.ar"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    &nbsp;info@webdelseguro.com.ar
                  </a>
                </li>
              </ul>
              <a
                className="social-btn sb-style-6 sb-facebook sb-light-skin"
                href="https://www.facebook.com/webdelseguro"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="socicon-facebook" />
              </a>
              <a
                className="social-btn sb-style-6 sb-instagram sb-light-skin"
                href="https://www.instagram.com/webdelseguro/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="socicon-instagram" />
              </a>
              <a
                className="social-btn sb-style-6 sb-youtube sb-light-skin"
                href="https://www.youtube.com/channel/UCngnk9h5p6NHiDPAxsLelLg/featured"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="socicon-youtube" />
              </a>
            </div>
            <div className="col-lg-6">
              <div className="widget widget-light-skin mb-0">
                <h4 className="widget-title">Secciones importantes</h4>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="widget widget-categories widget-light-skin">
                    <ul>
                      <li>
                        <Link to="/nosotros">Sobre Nosotros</Link>
                      </li>
                      <li>
                        <Link to="/siniestros">Siniestros</Link>
                      </li>
                      <li>
                        <Link to="/preguntas-frecuentes">Preguntas Frecuentes</Link>
                      </li>
                      <li>
                        <a
                          href="https://blog.webdelseguro.com.ar"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Blog
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="widget widget-categories widget-light-skin">
                    <ul>
                      <li>
                        <Link to="/siniestros">Aseguradoras</Link>
                      </li>
                      <li>
                        <Link to="/seguros">Cotiza tu Seguro</Link>
                      </li>
                      <li>
                        <Link to="/siniestros">Siniestros Seguros</Link>
                      </li>
                      <li>
                        <Link to="/contacto">Contacto</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 d-none d-lg-block">
              <div className="widget widget-categories widget-light-skin">
                <h4 className="widget-title">Cotizar Seguros</h4>
                <ul>
                  <li>
                    <Link to="/">
                      <i className="fas fa-car text-white opacity-50" />
                      Seguro Automotor
                    </Link>
                  </li>
                  <li>
                    <Link to="/seguros/hogar">
                      <i className="fas fa-home text-white opacity-50" />
                      Seguro Hogar
                    </Link>
                  </li>
                  <li>
                    <a href="/seguros/comercio">
                      <i className="fas fa-building text-white opacity-50" />
                      Seguro Comercio
                    </a>
                  </li>
                  <li>
                    <a href="/seguros/cotizar-art">
                      <i className="fas fa-tools text-white opacity-50" />
                      Seguro ART
                    </a>
                  </li>
                  <li>
                    <a href="/seguros/tecnologia">
                      <i className="fas fa-desktop text-white opacity-50" />
                      Seguro Tecnología
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* Subscription */}
        <div className="pt-5" style={{ backgroundColor: "#30363d" }}>
          <div className="container">
            <div className="row justify-content-center pb-5">
              <div className="col-xl-12">
                <ul className="ul-log list-inline text-center">
                  <li>
                    <a href="http://www.jus.gob.ar/datos-personales.aspx/">
                      <img src={pdpPng} alt="PDP" />
                    </a>
                  </li>
                  <li>
                    <a href="http://www2.ssn.gob.ar/">
                      <img src={ssn} alt="SSN" />
                    </a>
                  </li>
                  <li>
                    <a href="http://qr.afip.gob.ar/?qr=SrRRAW4Q6dxzM0S_E3QWYA,,">
                      <img src={afip} alt="AFIP" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <hr className="hr-light" />
            <div className="d-md-flex justify-content-between align-items-center py-4 text-center text-md-left">
              <p className="m-0 text-sm text-white order-1">
                <span className="opacity-60">
                  2024 © Todos los derechos reservados - Web del Seguro
                </span>
              </p>
            </div>
          </div>
        </div>
      </footer>

      <a href="https://api.whatsapp.com/send?phone=5491123552334" className="nwhatsapp" target="_blank">
        <i className="fab fa-whatsapp nwhatsapp-icon"></i>
      </a>

      {/* Back To Top Button */}
      <a className="scroll-to-top-btn" href="/">
        <i className="fe-icon-chevron-up" />
      </a>
      {/* Backdrop */}
      <div className="site-backdrop" />
    </>
  )
}
