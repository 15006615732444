import React from "react"

export default class extends React.Component {
  componentDidMount = () => {
    const $ = window.$
    const $container = $(this.container)
    $container.find('[data-toggle="offcanvas"]').on("click", function(e) {
      const $target = $(this).attr("href")
      $($target).addClass("in-view")
      e.preventDefault()
    })
    $container.find(".offcanvas-close").on("click", function() {
      $container.find(".offcanvas-container").removeClass("in-view")
    })
  }

  componentWillUnmount = () => {
    const $ = window.$
    const $container = $(this.container)
    $container.find('[data-toggle="offcanvas"]').off("click")
    $container.find(".offcanvas-close").off("click")
  }

  render = () => (
    <div ref={el => (this.container = el)}>{this.props.children}</div>
  )
}
